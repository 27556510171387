/**
 * @description i18n zh-CN
 * @author wangfupeng
 */

export default {
  editor: {
    more: '更多',
    justify: '对齐',
    indent: '缩进',
    image: '图片',
    video: '视频',
  },
}
